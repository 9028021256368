@media screen and (min-width: 320px) and (max-width: 768px) {
.header-row 
{
display: none;   
 }

.navbar-brand-col img{
    width: 100%;
}
.header-row{
    background-color: red;
}
.responsive-nav{
    position: relative;
    right:5%;
    display: flex;
    justify-content: end;
    align-items: center;


}
.navbar-brand img {
    padding: 10px;
    margin-left: -15%;
    width: 100%;
}
.form-col-lg-3 {
    display: none;
}
/* .container-fluid{
    padding: 10px !important;
} */
.products-brand-menu .flex-row {
    display: flex;
    justify-content: space-around;

}
.products-brand-menu .flex-row .nav-item{
    width: 120px;
    padding: 10px 0px;
}
.who-are-section .row {
    padding: 0px 0px;
}
.who-are-section .col-lg-6 img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.why-choose-us .col-lg-2{
    margin: 10px 0px;
}
.textimonials .row{
    padding: 0px ;
}
.test-mon .card{
    padding: 5px 25px;
}

.footer-menu-row {
    padding: 0px;
    padding: 15px 0px;
    
}
.footer-container{
    padding: 0px !important;
}
.products-brand-menu .nav-item .nav-link{
    margin: 0px;
}
.small-box-fixed-postion{
    position: relative;
}
.navbaer-menu-main-div{
    width: 100%;
  }

  #basic-navbar-nav {
    justify-content: start;
}
.navbar-container{
    margin: 0px 10px ;
}
.small-box-fixed-postion{
    padding: 0px !important;
    right: 0;
}
.book-a-text-drive .btn {
    width: 120px;
    border: none !important;
    height: 45px !important;
    font-size: 12px !important;
}
.red-btn{
    width: 120px;
    font-size: 12px !important;
}
.subcontent-div h6{
    font-size: 14px ;
}
.sticky-carovieview-nav-row{
    padding: 0px !important;
}
.sticky-carovieview-nav-row .col-lg-12{
    padding: 0px !important;
}
.caroverview-icon-div img {
    width: 30px;
    height: 30px;
}
.caroverview-feture-div h6{
   font-size: 12px !important;
}
.featers-main-haeding{
    font-size: 10px !important;
}

.small-box-fixed {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    padding: 0px !important;
    width: 50% !important;
}

.small-device .caroverview-icon-div {
  
    border-right: none !important;
}

.extra-border .caroverview-icon-div{
    border-right: 1px solid red !important;
}

.small-box-fixed .first-and-main-div {
    padding: 10px 0px 10px 0px !important; 
    align-items: center;
    justify-content: center;
}

.car-overview-container {
    padding: 0px 0px !important;
}
  }
  
 