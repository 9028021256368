/* BlogDetails.css */

.blog-details-container {
    padding: 0px 48px 0px 40px;
}

.related-blogs-title {
    font-size: 18px;
    margin-bottom: 15px;
}

.latest-news-item {
    display: flex;
    flex-direction: column;
}

.latest-news-link {
    text-decoration: none;
}

.latest-news-image {
    width: 100%;
    border-radius: 5px; /* Optional styling */
}

.latest-news-info {
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: 12px;
    margin-top: 5px;
}

.latest-news-title {
    font-size: 14px;
}

.latest-news-date {
    font-size: 14px;
}

.blog-content {
    margin-top: 20px; /* Optional spacing */
}

.blog-image {
    width: 100%;
    border-radius: 5px; /* Optional styling */
}



