@media (max-width: 576px) {
    .navbar-toggler{
        height: 60%;
        position: relative;
    
        right: 0%;
        margin-top: 5%;
        
    }
    .model-bootom{
        margin-bottom: 1rem !important;
    }
    .product-images-details-carousel{
        height: 150px;
    }
    .product-images-details-carousel .col-div-responsive{
        height: 100% !important;
    }
    /* .parent-container{
        height: 100% !important;
    } */
    .know-more{
        margin-bottom: 10%;
    }
    .textimonials .card{
        padding: 10px !important;
    }
    .textimonials .card .post-txt{
  font-size: 12px;
    }
    .footer-page-icon-row {
        background-color: #100f0f;
        height:100%;
        padding: 0px 0px;
    }
    .price-bar{
        display: flex;
        justify-content: space-between;
        width: 100% !important;
    }
    .price-stcicy-bar{
        justify-content: start !important;
        width: 100% !important;
        padding: 0px 10px;

    }
    .selling-form select{
        font-size: 10px ;
    }
    .sell-component-row .col-lg-4 span{
        font-size: 12px;
    }
    .sell-component-row .col-lg-4 img{
        width: 30px;
        height: 30px;
        object-fit: cover;
    }
    .sell-component-row .col-lg-4{
       margin-bottom: 8%;
    }
    .sub-heading{
        margin-top: 5%;
    }
    .home-page-add-row{
        padding: 5px 0px !important;
    }
   .home-page-add-row .col-sm-4 img{
    width: 100%;

   }
   .home-page-add-row .col-sm-4 .home-add-heading{
    font-size: 10px;
   }
   .home-page-add-row .col-sm-4 .full-price{
    font-size: 10px;

   }
   .home-page-add-row .col-sm-4 h5{
    font-size: 10px;

   }
   .home-page-add-row .col-sm-4 p span{
    font-size: 10px !important;

   }
   .home-page-add-row .col-sm-4 p{
    font-size: 10px;

   }
   .products-heading h3{
    font-size: 18px ;
   }
    .accordion-header button{
        font-size: 12px;

    }
    .accordion-body p{
        font-size: 12px;

    }
    .about-links{
        font-size: 10px ;
    }
    .navbar-grid-div .navbar-nav {
        align-items: start;
        justify-content: start !important;
    }
    .navbar-nav .nav-link{
        padding: 5px 0px !important;

    }
    
  }

  @media (max-width: 768px) {
    .navbar-toggler{
        height: 60%;
        position: relative;
    
        right: 3%;
        margin-top: 8%;
        
    }
    .header-row{
          display: none !important;
    }
    
    .price-bar span{
        font-size: 8px !important;
    }
    .form-control{
        font-size: 10px !important;
    }
    .serach-btns span{
        font-size: 10px;
    }
    /* .navbar-nav .nav-link{
        padding: 0px !important;
    } */
    
    .brand-details-row-2{
        padding: 0px 30px;
    }
    .interior div {
        background-color: black;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 38px;
        opacity: 0.7;
        position: absolute;
        padding: 20px;
    }
    .interior div p {
        font-size: 8px;
    }
    .vertical-slider{
        display: none;
    }

    .caroverview-icon-div {
        display: flex;
        justify-content: center;
        border-right: 0px;
    }
    .mobile-border .caroverview-icon-div{
        border-right: 1px solid red !important;
    }
    .homepage-container {
        padding: 0px !important;
    }
    .price-bar span{
        font-size: 6px !important;
    }
    .caroverview-icon-div {
        display: flex;
        justify-content: center;
        border-right: 0px;
    }
    .mobile-border .caroverview-icon-div{
        border-right: 1px solid red !important;
    }
    .dealer-page-container{
        padding: 0px 10px !important;
    }
   
  }
  

  @media (max-width: 992px) {
    .navbar-toggler{
        height: 60%;
        position: relative;
        right: 0%;
        margin-top: 5%;    
    }
    .serach-btns-home .btn{
        font-size: 10px ;
    }

    .price-bar span{
        font-size: 10px !important;
    }
    .form-control{
        font-size: 10px !important;
    }
    .serach-btns span{
        font-size: 10px;
    }
    /* .navbar-nav .nav-link{
        padding: 0px !important;
    } */
    .header-row .col-lg-12 {
        display: flex;
        justify-content: end;
        position: relative;
        left: 0%;
    }

    .bg-body-tertiary .container-fluid .row {
        width: 100%;
        padding: 0px;
    }
    .header-row{
        padding: 0px 10px !important;

    }
    /* .navbar-grid-div .navbar-nav {
        align-items: center;
        justify-content: space-between !important;
    } */
    .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 20px 0px 0px !important;
    }
    .who-we-are-image{
        width: 100% !important;
        object-fit: cover;
    }
    .why-choose-ua-col-md{
        margin-bottom: 20px;
    }
    
  }

  @media (max-width:1200px){
    .post-txt{
        font-size: 14px;
    }
    .emi-price{
        font-size: 16px !important;
    }
    .emi-price-h3{
        font-size: 16px !important;
    }
    .full-amount-price{
        font-size: 14px !important;
    }
   
  }

  @media (min-width:990px) and (max-width:1200px){
     .navbar-nav .nav-link{
        padding: 0px !important;
    }
    .navbar-grid-div .navbar-nav {
        align-items: center;
        justify-content: space-between !important;
    }
    .price-bar span{
        font-size: 10px !important;
    }
  }

  @media (max-width:767px){
    .form-col-lg-3 {
        display: none;
    } 
    .form-col-lg-4{
        display: block;
        padding: 0px !important;
        margin: 10px 0px 0px 0px !important;
        background-color: black;
    }
    .form-col-lg-4 h6{
        background-color: red;
    }
    .form-col-lg-4 form{
        padding: 0px  25px ;
    }
    .tabes-container{
        padding: 0px 20px !important;
    }
    .who-are-section .row{
        padding: 0px !important;
    }
    .homepage-faqs .col-lg-10{
        padding: 0px !important;
    }
    .text-left{
        padding: 0px !important;
    }
 }

    
  

/* .parent-container{
    height: 425px;
} */
  

.vertical-slider {
    height: 70vh; /* Adjust based on your design */
  }
  
  @media (max-width: 576px) {
    .vertical-slider {
      height: 36vh;
    }
  }

  @media (min-width: 1200px){
    .interior div {
        background-color: black;
        width: 80%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 38px;
        opacity: 0.7;
        position: absolute;
        padding: 20px;
        left: 18.5%;
    }
    .exterior-images-gallery .image-gallery-thumbnails-left{
        padding-left: 12px !important;
    }
    
  }
  .image-gallery-thumbnail{
    border-radius: 13px !important;
  }
  .exterior-images-gallery .image-gallery-thumbnails-left .image-gallery-swipe{
    border-radius: 10px !important;
  }
  .exterior-images-gallery .image-gallery-thumbnails-left .image-gallery-slides{
    border-radius: 10px !important;

  }

  .image-gallery-container {
    width: 100%; /*Full width */
    max-width: 800px; /*Adjust as needed */
    margin: auto; /*Center the gallery */
}

.image-gallery-thumbnail {
    cursor: pointer; /*Indicate clickable thumbnails */
}

@media (min-width:1280px) and (max-width:2250px){
    .blue-btn{
        font-weight: 500 !important;
    }
    .red-btn{
        font-weight: 500 !important; 
    }
}

@media (min-width:992px) and (max-width:1200px){
    .interior div {
        background-color: black;
        width: 72%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 38px;
        opacity: 0.7;
        position: absolute;
        padding: 20px;
        left: 22.5%;
    }
  
}

@media (min-width:1400px) and (max-width:2550px){
    .interior div {
        background-color: black;
        width: 50%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 38px;
        opacity: 0.7;
        position: absolute;
        padding: 20px;
        left: 32%;
    }
  
}

@media (min-width:769px){
    .form-col-lg-4{
        display: none;
    }
}





